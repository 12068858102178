var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"id":"modal-xl","title":"Edit Language","centered":"","size":"lg","ok-title":"Submit","cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('b-card',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{ref:"name",attrs:{"rules":{ required: true },"vid":"name","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","placeholder":"Name","state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.localeUpdated.name),callback:function ($$v) {_vm.$set(_vm.localeUpdated, "name", $$v)},expression:"localeUpdated.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{ref:"abbreviation",attrs:{"rules":{ required: true, max: 3 },"vid":"abbreviation","name":"abbreviation"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Abbreviation","label-for":"abbreviation"}},[_c('b-form-input',{attrs:{"id":"abbreviation","max":"3","placeholder":"Abbreviation","state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.localeUpdated.abbreviation),callback:function ($$v) {_vm.$set(_vm.localeUpdated, "abbreviation", $$v)},expression:"localeUpdated.abbreviation"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","offset":"6"}},[_c('validation-provider',{ref:"code",attrs:{"rules":{ required: true },"vid":"code","name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"ISO Language Code","label-for":"lang-code"}},[_c('v-select',{attrs:{"id":"lang-code","label":"text","placeholder":"Select Code","options":_vm.localeCodes,"state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.localeUpdated.code),callback:function ($$v) {_vm.$set(_vm.localeUpdated, "code", $$v)},expression:"localeUpdated.code"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"description",attrs:{"rules":{ required: true },"vid":"description","name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Description")]),_c('b-form-textarea',{attrs:{"id":"lang-description","placeholder":"","rows":"3","state":_vm.getValidationState(
                    validationContext
                  )},model:{value:(_vm.localeUpdated.description),callback:function ($$v) {_vm.$set(_vm.localeUpdated, "description", $$v)},expression:"localeUpdated.description"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }