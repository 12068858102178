<template>
  <b-card>
    <b-skeleton-table
      v-if="isLoading"
      :rows="5"
      :columns="4"
      :table-props="{ bordered: true, striped: true }"
    />
    <!-- Table Top -->
    <b-row
      v-if="!isLoading"
      class="list-top-buttons"
    >
      <b-col
        cols="30"
        md="12"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="showAddLanguages"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
              class="mr-50"
            />
            <span class="text-nowrap">Add Language</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="!isLoading" 
      class="mt-3"
    >
      <b-col
        cols="2"
        md="6"
      />
    </b-row>
    <b-container
      v-if="!isLoading"
      class="mt-1"
    >
      <b-row>
        <b-col>
          <div class="align-items-left">
            <span class="header">Sort</span>
          </div>
        </b-col>
        <b-col>
          <div class="align-items-left">
            <span class="header">Name</span>
          </div>
        </b-col>
        <b-col>
          <div class="align-items-left">
            <span class="header">ISO Language Code</span>
          </div>
        </b-col>
        <b-col>
          <div class="align-items-left">
            <span class="header">Edit</span>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- draggable -->
    <draggable
      v-if="!isLoading"
      v-model="languages"
      class="list-group list-group-flush cursor-move"
      tag="ul"
      :move="updateListSortOrder"
    >
      <b-list-group-item
        v-for="lang in languages"
        :key="lang.id"
        tag="li"
      >
        <b-container>
          <b-row class="d-flex align-items-center">
            <b-col class="sort-wrapper">
              <feather-icon
                icon="MoveIcon"
                size="20"
              />
            </b-col>
            <b-col>
              <div class="align-items-left">
                <span>{{ lang.name }}</span>
              </div>
            </b-col>
            <b-col class="align-items-center">
              <div class="align-items-center">
                <span class="align-items-center">{{ lang.code }}</span>
              </div>
            </b-col>
            <b-col>
              <div
                class="align-items-center"
              >
                <div class="align-items-center">
                  <b-button
                    variant="outline-none"
                    @click="editLocale(lang)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="20"
                    />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-list-group-item>
    </draggable>
    <!-- Add Language -->
    <add-language-vue
      ref="addLanguage"
      @languageAdded="onLanguageAdded"
    /> 
    <!-- Edit Language -->
    <edit-language-vue
      ref="editLanguage"
      :locale="localeToEdit"
      @languageAdded="onLanguageAdded"
    /> 
  </b-card>
</template>

<script>
import {
  BCard, BListGroupItem, BButton, BRow, BCol, BContainer, BSkeletonTable
} from 'bootstrap-vue';
import draggable from 'vuedraggable';
import Ripple from 'vue-ripple-directive';
import AddLanguageVue from './AddLanguage.vue';
import EditLanguageVue from './EditLanguage.vue';
import localesService from '@services/localesService.js';
import _delay from "lodash/delay";
export default {
  name: "Languages",
  components: {
    draggable,
    BContainer,
    BCard,
    BListGroupItem,
    BButton,
    BRow,
    BCol,
    BSkeletonTable,
    AddLanguageVue,
    EditLanguageVue,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      /* eslint-disable global-require */
      modalShowAdd: false,
      modalShowEdit: false,
      element : {},
      isLoading: false,
      languages: [],
      localeToEdit: undefined
    };
  },
  created() {
    this.loadSystemLocales();
  },
  methods: {
    async loadSystemLocales() {
      try {
        this.isLoading = true;
        const response = await localesService.getLocalesList();
        const {data } = response;
        this.languages = data;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    showAddLanguages () {
      this.$refs.addLanguage.show();
    },
    onLanguageAdded () {
      this.loadSystemLocales();
    },
    editLocale(lang) {
      this.localeToEdit = lang;
      this.$refs.editLanguage.show();
    },
    updateListSortOrder(evt) {
      const { element, futureIndex } = evt.draggedContext;
      const localeToUpdate = this.languages.find(locale => locale.id === element.id);
      const locale = {
        ...localeToUpdate,
        locale_order: futureIndex
      };
      _delay(this.updateSortOrder, 3000, locale);
    },
    async updateSortOrder(locale) {
      try {
        const {id} = locale; 
        await localesService.patchUpdateLocale(id, locale);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .list-group-item {
    transition: all 1s
  }
  .list-group-flush {
    border: 1px solid rgba(34, 41, 47, 0.125);
    border-width: 1px;
  }
  .header{
    font-size: 16px;
    font-weight: 700;
  }
  .locale-flag {
    font-size: 40px;
  }
  .sort-wrapper svg {
    display: flex;
    flex-direction: column;
  }
</style>
