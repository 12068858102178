/* eslint-disable camelcase */
export class LocaleClass {
    constructor ({
        id,
        name = '',
        code = '',
        created_at = '',
        abbreviation = '',
        description = '',
        locale_order = '',
    } = {}) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.created_at = created_at;
        this.abbreviation = abbreviation;
        this.description = description;
        this.locale_order = locale_order;
    }

}
