<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-xl"
      v-model="modalShow"
      title="Add New Language"
      centered
      size="lg"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-card>
        <b-form @submit.prevent>
          <b-row>
            <!-- first name -->
            <b-col md="6">
              <validation-provider
                v-slot="validationContext"
                ref="name"
                :rules="{ required: true }"
                vid="name"
                name="name"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="locale.name"
                    placeholder="Name"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- mobile -->
            <b-col md="6">
              <validation-provider
                v-slot="validationContext"
                ref="abbreviation"
                :rules="{ required: true, max: 3 }"
                vid="abbreviation"
                name="abbreviation"
              >
                <b-form-group
                  label="Abbreviation"
                  label-for="abbreviation"
                >
                  <b-form-input
                    id="abbreviation"
                    v-model="locale.abbreviation"
                    max="3"
                    placeholder="Abbreviation"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
              offset="6"
            >
              <validation-provider
                v-slot="validationContext"
                ref="code"
                :rules="{ required: true }"
                vid="code"
                name="code"
              >
                <b-form-group
                  label="ISO Language Code"
                  label-for="lang-code"
                >
                  <v-select
                    id="lang-code"
                    v-model="locale.code"
                    label="text"
                    placeholder="Select Code"
                    :options="localeCodes"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  >
                    {{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <validation-provider
                v-slot="validationContext"
                ref="description"
                :rules="{ required: true }"
                vid="description"
                name="description"
              >
                <label for="textarea-default">Description</label>
                <b-form-textarea
                  id="lang-description"
                  v-model="locale.description"
                  placeholder=""
                  rows="3"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                />
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm,
  VBModal, BFormTextarea, BModal, BFormInvalidFeedback
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from 'vue-ripple-directive';
import localesService from '@services/localesService.js';
import vSelect from "vue-select";
import { LocaleClass } from './model/LocaleClass';
//eslint-disable-next-line
import { required, max } from "@validations";
import {
    getValidationState
} from "@/libs/utils";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormInvalidFeedback,
    BModal,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data: () => ({
      modalShow: false,
      localeCodes: undefined,
      locale: new LocaleClass()
  }),
  created() {
    this.loadLocaleCodes();
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    async loadLocaleCodes() {
      try {
        this.isLoading = true;
        const response= await localesService.getLocaleCodes();
        const { data} = response;
        this.localeCodes = data;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async onSubmit () {
      try {
          await localesService.postCreateLocale(this.locale);
          this.modalShow = false;
          this.$emit("languageAdded");
          return;
      } catch (e) {
          const { status, data } = e.response;
          //server may respond with vaidation errors
          if (status === 422 && data.errors) {
              this.$refs.observer.setErrors(data.errors);
          } else {
              this.$log.error(e);
          }
          return;
      }
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.locale = new LocaleClass();
      this.$nextTick(() => {
          this.$refs.observer.reset();
      });
    },
  },
  setup() {
    return {
        getValidationState
    };
  }
};
</script>
